html,
body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background: rgb(245,245,245);
}

.container{
    margin: 60px auto 0 auto;
    max-width: 1200px;
}

a{
    text-decoration: none;
}
